import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import AppContext from "./AppContext";

const LayoutDisableHeader = ({ children }) => {
  const { layoutShowHeader, setLayoutShowHeader } = useContext(AppContext);

  useEffect(() => {
    setLayoutShowHeader(false); // disable for this page
    return () => {
      // restore state
      setLayoutShowHeader(true);
    };
  }, [layoutShowHeader, setLayoutShowHeader]);

  return <>{children}</>;
};

LayoutDisableHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutDisableHeader;
