import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import AppContext from "./AppContext";

const LayoutDisableFooter = ({ children }) => {
  const { layoutShowFooter, setLayoutShowFooter } = useContext(AppContext);

  useEffect(() => {
    setLayoutShowFooter(false); // disable for this page
    return () => {
      // restore state
      setLayoutShowFooter(true);
    };
  }, [layoutShowFooter, setLayoutShowFooter]);

  return <>{children}</>;
};

LayoutDisableFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutDisableFooter;
