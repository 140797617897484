import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import Seo from "../components/Seo";
import LayoutDisableHeader from "../components/LayoutDisableHeader";
import LayoutDisableFooter from "../components/LayoutDisableFooter";

// markup
const NotFoundPage = () => {
  return (
    <LayoutDisableHeader>
      <LayoutDisableFooter>
        <Seo>
          <title>Not found</title>
        </Seo>

        <div className="my-5 container">
          <h1>Page not found</h1>
          <p>
            <Link to="/">Go home</Link>
          </p>
        </div>
      </LayoutDisableFooter>
    </LayoutDisableHeader>
  );
};

export default NotFoundPage;
